@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

:root {
  --ion-font-family: "Inter", sans-serif;
  --ion-background-color: #fafafa;
}

/* Ionic overrides */
.ion-item-product-override {
  --ion-border-color: transparent;
  --ion-background-color: white;
}

.ion-list-product-override {
  --ion-background-color: white;
}

.ion-item-options-product-override {
  --ion-border-color: transparent;
}

.ion-toolbar-modal-override {
  --background: white;
  --border-color: transparent;
}

.ion-content-modal-override {
  --background: white;
  --border-color: transparent;
}

.autocomplete-ion-list-override {
  --background: white;
}

.ion-textarea-override {
  --background: red !important;
}

/* Ionic Inbox Card Overrides */
.ion-item-card-override {
  --inner-padding-start: 0px !important;
  --inner-padding-end: 0px !important;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
  --border-color: transparent !important;
}

/* Tailwind overrides, this is kinda janky, but we only have 2 textareas in use rn and they don't need borders */
textarea:focus,
input:focus {
  --tw-ring-color: transparent !important;
  border-color: transparent !important;
}

input:disabled {
  opacity: 1;
}

.chart-js canvas {
  width: 100% !important;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.ion-input-disabled-override {
  opacity: 1 !important;
  color: rgba(17, 20, 46, 1) !important;
}

/* Barcode scanner */
body.scanner-active {
  --background: transparent;
  --ion-background-color: transparent;
}
