/* Remove the drop shadow around the datepicker */
.DatePicker {
  box-shadow: none;
}

/* Change the active day color */
.CalendarDay__today {
  background-color: rgba(17, 20, 46, 0.05);
  color: black;
}

/* Change the hover background color */
.CalendarDay:hover,
.CalendarDay__hovered_span:hover {
  background-color: rgba(30, 95, 239, 1);
}

/* Change the date range color */
.CalendarDay__selected_span {
  background-color: rgba(30, 95, 239, 0.1);
  color: black;
}

/* Change the selected days background color */
.CalendarDay__selected,
.CalendarDay__selected_start,
.CalendarDay__selected_end {
  background-color: rgba(30, 95, 239, 1);
  color: white;
}

/* Hide the question mark for keyboard shortcuts */
.DayPickerKeyboardShortcuts_show__bottomRight::before {
  display: none;
}

/* Hide the border around each day */
.CalendarDay,
.CalendarDay:hover {
  border: none;
}

.CalendarDay:hover {
  color: white;
}

.CalendarDay__selected:hover {
  border: none;
  color: white;
}

/* Set light blue background behind the selected days */
.CalendarDay__selected_span.CalendarDay__hovered_span,
.CalendarDay__selected_span.CalendarDay__hovered_span:hover {
  background-color: rgba(30, 95, 239, 1);
  border: solid 1px rgba(30, 95, 239, 1);
  color: black;
}

/* Remove the box shadow on the day picker */
.DayPicker__withBorder {
  box-shadow: none;
}

/* Change the hover background color after selecting a date for the first time */
.CalendarDay__hovered_span:not(.CalendarDay__selected_start):not(
    .CalendarDay__selected_end
  ):hover {
  background-color: rgba(30, 95, 239, 0.1);
  color: black;
}

/* Change the hover background color for days between the selected date and the date you're hovering on */
.CalendarDay__hovered_span {
  background-color: rgba(30, 95, 239, 0.1);
  color: black;
}
